import { FC } from 'react'
import styles from './AlertInline.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Text } from 'src/ui/components/atoms/Text'

export type AlertInlineTypes =
  | 'warning'
  | 'info'
  | 'success'
  | 'error'
  | 'neutral'

interface Props {
  type: AlertInlineTypes
  text?: string | JSX.Element
  className?: string
  ariaLabel?: string
  'data-testid'?: string
}

export const AlertInline: FC<Props> = ({
  type,
  text,
  className,
  ariaLabel,
  'data-testid': testId,
}) => {
  const getText = () => {
    if (typeof text === 'string') {
      return (
        <Text
          fontStyle="s-500"
          color={type === 'success' ? 'support-success-dark' : 'dark'}
        >
          {text}
        </Text>
      )
    }

    return text
  }

  return (
    <div
      className={classNames(styles.alertInlineWrapper, className)}
      aria-label={ariaLabel}
      data-testid={testId}
    >
      <div className={classNames(styles.alertInline, styles[type])}>
        <div role="alert">{getText()}</div>
      </div>
    </div>
  )
}
